// TABS

.nav-pills,
.nav-tabs {
  margin-bottom: $spacer;
}

.nav-link {
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.nav-tabs {
  .nav-link {
    &:hover {
      color: $primary !important;
    }

    &.active {
      color: $primary;
    }
  }
}

.nav-pills {
  .nav-link {
    &:hover {
      color: $primary !important;
    }

    &.active {
      background: $primary;

      &:hover {
        color: $white !important;
      }
    }
  }
}

// Fancy Tabs

.RRT__container {
  position: relative;
}

.RRT__tabs {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.RRT__accordion {
  flex-direction: column;
}

.RRT__tab {
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  padding: $nav-link-padding-y $nav-link-padding-x;
  transition: all 0.2s;

  &:hover {
    color: $primary;
  }

  &:focus {
    outline: 0;
  }
}

.RRT__tab--selected {
  color: $primary;
}

.RRT__tab--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.RRT__tab:focus {
  z-index: 2;
}

.RRT__tab--selected .RRT__removable {
  position: relative;
}

.RRT__tab--selected .RRT__removable-text {
  margin-right: 10px;
}

.RRT__tab--selected .RRT__removable-icon {
  position: absolute;
  font-size: 18px;
  right: 0.5em;
  top: 0.2em;
}

// Alternate Style

// Panels

.RRT__panel {
  margin-top: $spacer;
}

// Show More

.RRT__showmore {
  @include border-radius($border-radius);
  background: $primary;
  cursor: pointer;
  z-index: 1;
  white-space: nowrap;
  font-weight: bold;
  width: 36px;
  text-align: center;
  height: 36px;
  display: block;
  margin-left: 15px;
  position: absolute;
  right: $grid-gutter-width;
  top: 50%;
  margin-top: -18px;
  font-size: 1.4rem;

  &:focus {
    outline: none;
  }
}

.RRT__showmore-label {
  position: relative;
  z-index: 1;
  color: $white;
  width: 36px;
  text-align: center;
  height: 36px;
  line-height: 24px;
}

.RRT__showmore--selected {
  box-shadow: 0 16px 26px -10px rgba($primary, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($primary, 0.2);
}

.RRT__showmore-list {
  position: absolute;
  right: -1px;
  top: 100%;
  display: none;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  box-shadow: $box-shadow-default;

  .RRT__tab {
    display: block;
    width: 100%; // For `<button>`s
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit; // For `<button>`s
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s
    margin: 0;

    &:hover,
    &.RRT__tab--selected {
      color: $dropdown-link-hover-color !important;
      text-decoration: none;
      background: $dropdown-link-hover-bg;
    }
  }
}

.RRT__showmore-list--opened {
  display: block;

  .RRT__tab--selected {
    color: $primary;
  }
}

// Inkbar

.RRT__inkbar-wrapper {
  width: 100%;
}

.RRT__inkbar {
  position: relative;
  bottom: 0;
  height: 3px;
  margin-top: -3px;
  background-color: $primary;
  @include border-radius(15px);
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  z-index: 2;
}

.body-tabs {
  border-bottom: $gray-300 solid 2px;

  & > .RRT__tab {
    padding: ($layout-spacer-x / 1.5) ($layout-spacer-x / 1.2);
    font-weight: normal;
    font-size: $font-size-base;
  }

  &.body-tabs-alt {
    & > .RRT__tab {
      padding: ($layout-spacer-x / 1.1) ($layout-spacer-x / 3);
      font-weight: normal;
      font-size: 0.86rem;
      width: 33%;
      text-align: center;
    }

    .RRT__inkbar {
      height: 2px;
      margin-top: -2px;
    }
  }
}
