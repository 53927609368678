// Main Base

.app-main {
  flex: 1;
  display: flex;
  z-index: 8;
  position: relative;

  .app-main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 12;
  }

  .app-main__inner {
    padding: $grid-gutter-width $grid-gutter-width 0;
    flex: 1;

    & > .RRT__container {
      & > .RRT__panel {
        margin-top: $grid-gutter-width;
      }

      & > .body-tabs-layout {
        .RRT__tab {
          margin-top: (-($layout-spacer-x / 3));
        }
      }
    }
  }
}

// Themes

@import 'themes/main-white';
