.submitButton {
  background: #d54d29 !important;
  color: #ffffff !important;
  border: none !important;
  height: 50px;
  font-size: 1.2rem !important;
}
.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 119vh;
  
  /* height: 50%; */
  border-bottom: 1px solid black;
}
.loginContainer .card-title {
  text-align: center;
  font-size: 1.2rem !important;
}
.loginContainer img {
  width: 100%;
  max-width: 160px;
  display: block;
  margin: 2rem auto;
}
.loginContainer .main-card {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
